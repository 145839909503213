import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, PopularItems } from '../components';
import { areaList } from '../data/main';
import {
	getBase64Images,
	getProduct,
	getProductImages,
	getReviews,
	getShippingFees,
	getShippingMethod,
	imageSrc
} from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';
import { ToastContext } from '../functions/context/ToastFunc';

const ProductImagesCarousel = ({images, base64Images}) => {
	const [imageIdx, setImageIdx] = useState(0);

	return (
		(images && images[imageIdx]) ? (
			<div className='sm:mb-[40px] sm:mb-[60px] relative z-0'>
				<img
					src={imageSrc(base64Images[images[imageIdx].base64Images_idx])}
					alt='商品画像'
					className='w-[640px] h-[200px] sm:h-[400px] object-contain bg-stone-200' />
				{imageIdx ? 
					<button onClick={() => setImageIdx(imageIdx - 1)} className="absolute w-10 h-10 top-1/2 left-4 -translate-y-1/2 bg-white rounded-full opacity-50 hover:opacity-80" data-carousel-prev>
						<Icon icon="circle-chevron-left" className="opacity-50 hover:opacity-80" />
					</button>
				: ''}
				{imageIdx < images.length - 1 ? 
					<button onClick={() => setImageIdx(imageIdx + 1)} className="absolute w-10 h-10 top-1/2 right-4 -translate-y-1/2 bg-white rounded-full opacity-50 hover:opacity-80" data-carousel-next>
						<Icon icon="circle-chevron-right" className="opacity-50 hover:opacity-80" />
					</button>
				: ''}
			</div>
		) : ''
	);
};

const ProductReview = ({productId}) => {
	const [reviews, setReviews] = useState([]);
	const loading_context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			loading_context.setLoading(true);
			const reviewsData = await getReviews(productId, true);
			setReviews(reviewsData);
			loading_context.setLoading(false);
		}
		getData();
	}, []);

	if (reviews.length)
		return (
			<div className='pt-[80px] pb-[40px] sm:py-[80px]'>
				<p className='font-allura sm:text-[20px] text-gold-dark'>Review</p>
				<p className='mb-[20px] font-shippori text-[20px] sm:text-[32px]'>カスタマーレビュー</p>
				{ reviews.map((review) => { return (
					<div className='sm:px-[20px] py-[10px] flex flex-col gap-[5px] border-b'>
						<div className='flex gap-[5px]'>
							<Icon icon='circle-user' className="w-[14px] sm:w-[16px]" />
							<p className='text-[14px] sm:text-base font-mono'>{review.nickname}</p>
							<p className='text-[14px] sm:text-base font-mono font-bold text-stone-400'>{review.created_at}</p>
						</div>
						<div className='flex'>
							{ new Array(review.score).fill(<Icon icon="star" className="w-[20px] fill-gold" />) }
							{ new Array(5 - review.score).fill(<Icon icon="star-border" className="w-[20px] fill-gold" />) }
						</div>
						<p className='sm:text-[20px]'>「{review.title}」</p>
						<p className='leading-loose'>{review.content}</p>
					</div>
				)})}
			</div>
	);
};

const Product = () => {
	const params = useParams();
	const productId = Number(params.product_id);
	const [product, setProduct] = useState({});
	const [images, setImages] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const [shippingMethod, setShippingMethod] = useState({});
	const [shippingFees, setShippingFees] = useState([]);
	const [item, setItem] = useState({
		product_id: productId,
		number: 1,
		subscription: 0,
	});
	const { showBoundary } = useErrorBoundary();
	const toast_context = useContext(ToastContext);
	const loading_context = useContext(LoadingContext);
	const navigate = useNavigate();

	const handleChange = (e) => {
		const {name, value} = e.target;
		setItem({...item, [name]: Number(value), subscription: product.subscription });
		if (Number(value) > 0) {
			document.getElementById("button").removeAttribute("disabled");
			document.getElementById("button").className = 'w-full p-[10px] mx-auto mt-[20px] block text-gold-light font-bold bg-stone-800 rounded hover:bg-gold';
		} else if (document.getElementById("button").disabled === false) {
			document.getElementById("button").setAttribute("disabled", true);
			document.getElementById("button").className = 'w-full p-[10px] mx-auto mt-[20px] block text-gold-light font-bold bg-stone-400 rounded';
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const cart = JSON.parse(localStorage.getItem('cart'));

		for (var i = 0; i < cart.length; i ++) {
			if ((product.subscription || cart[i].subscription) && cart[i].number && cart[i].product_id !== product.id) {
				window.alert('定期便の商品と他の商品を同時に購入することはできません');
				return ;
			}
		}

		const same_item_i = cart.findIndex(({product_id}) => product_id === item.product_id);
		if ((same_item_i < 0 && product.stock < item.number)
			|| (same_item_i >= 0 && product.stock < cart[same_item_i].number + item.number))
			window.alert('在庫数を超えています');
		else if (same_item_i >= 0 && cart[same_item_i].subscription && cart[same_item_i].number > 0)
			window.alert('定期便の購入は1度につき1つまでです');
		else {
			if (same_item_i < 0)
				cart.push(item);
			else
				cart[same_item_i].number += item.number;
			localStorage.setItem('cart', JSON.stringify(cart));
			toast_context.setMessage('商品を買い物かごに追加しました');
			navigate('/cart');
		}
	};

	const handleActivitySubmit = (e) => {
		e.preventDefault();
		navigate(`/activity-form?id=${productId}&number=${item.number}`);
	};

	useEffect(() => {
		document.getElementById("button").setAttribute("disabled", true);
		if (!localStorage.getItem('cart'))
			localStorage.setItem('cart', JSON.stringify([]));

		const getData = async () => {
			loading_context.setLoading(true);

			var productData;
			try {
				productData = await getProduct(productId, true);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			if (productData) {
				var imagesData;
				var base64ImagesData;
				try {
					imagesData = await getProductImages(productId);
				} catch (err) {
					showBoundary(err);
					return ;
				}
				try {
					base64ImagesData = await getBase64Images({ table: 'products', images: imagesData });
				} catch (err) {
					showBoundary(err);
					return ;
				}
				setProduct(productData);
				setImages(imagesData);
				setBase64Images(base64ImagesData);
			} else {
				window.alert('商品が存在しません');
				loading_context.setLoading(false);
				window.location.href = '/products';
				return ;
			}

			if (productData.shipping_method) {
				var shippingMethodData;
				var shippingFeesData;
				try {
					shippingMethodData = await getShippingMethod(productData.shipping_method);
				} catch (err) {
					showBoundary(err);
					return ;
				}
				try {
					shippingFeesData = await getShippingFees(productData.shipping_method);
				} catch (err) {
					showBoundary(err);
					return ;
				}
				setShippingMethod(shippingMethodData);
				setShippingFees(shippingFeesData);
			}
			loading_context.setLoading(false);
		}
		getData();
	}, []);

	const ShippingModal = () => {
		if (shippingFees.length) {
			return (
				<dialog id="modal" className="w-[640px] h-[480px] modal modal-bottom sm:modal-middle p-4 rounded-md">
					<div className="modal-box">
						<form method="dialog">
							<button className="btn btn-sm btn-ghost absolute right-4 top-4">✕</button>
						</form>
						<h3 className="font-bold text-lg">送料・配送方法について</h3>
						<p className="py-4">{shippingMethod.name}</p>
						<table className='text-sm'>
							<thead>
								<tr className='bg-stone-100 border'>
									<th>地域</th>
									{ shippingFees.map((fee, i) => {
										return (<th key={i} className='px-2 text-center'>{fee.min_n}〜{fee.max_n}個</th>);
									})}
								</tr>
							</thead>
							<tbody>
								{areaList.map((area, i) => {return (
									<tr key={i} className='border'>
										<td>
											<p>{area.name}</p>
											<p className='text-xs'>{area.prefectures.join(',')}</p>
										</td>
										{ shippingFees.map((fee, i) => {
											return (<td key={i} className='px-2 text-center'>¥{new Intl.NumberFormat().format(fee[area.method_name])}</td>);
										})}
									</tr>
								)})}
							</tbody>
						</table>
					</div>
				</dialog>
			);
		} else {
			return <div />;
		}
	};

	return (
		<div className='w-[90%] sm:w-4/5 mt-[60px] sm:mt-[80px] pt-[20px] sm:pt-[80px] mx-auto'>
			{product.shipping_method ? <ShippingModal /> : ''}
			<a href='javascript:history.back()' className='text-sm hover:text-amber-600'>
				&lt; <span className='hover:underline'>一覧に戻る</span>
			</a>
			<div className='mt-[10px] sm:flex'>
				<div className='sm:w-3/5'>
					<ProductImagesCarousel images={images} base64Images={base64Images} />
					<div className='h-0 sm:h-fit hidden sm:block'>
						<p className='font-allura text-gold-dark text-[20px]'>Details</p>
						<p className='font-shippori text-[32px]'>詳細</p>
						<p className='mt-[20px] whitespace-pre-line leading-loose'>{product.description}</p>
					</div>
				</div>
				<div className='sm:w-2/5'>
					<div className='p-[20px] sm:h-[400px] bg-white bg-opacity-70'>
						<p className='font-bold text-[20px] sm:text-[24px]'>{product.name}</p>
						<p className='mt-[10px] text-[24px] sm:text-[40px] sm:font-bold font-mono'>
							¥ {new Intl.NumberFormat().format(product.price)}
						</p>
						{ (product.category !== '体験イベント' && product.sub_category !== '体験イベント') ? 
						<div>
							{product.shipping_method ? 
								<button
									onClick={(e) => {
										e.preventDefault();
										document.getElementById('modal').showModal()
									}}
									className="font-mono text-stone-400 flex items-center">
									<span className='mr-[5px] sm:text-[20px] font-bold'>+送料</span>
									<span className='text-[12px] hover:underline'>ⓘ送料について</span>
								</button>
								:
								<p className='font-mono text-stone-400 sm:text-[20px] font-bold'>送料なし</p>
							}
						</div>
						:
						''
						}
						{ product.stock ? 
						<div className='mt-[20px] flex items-center'>
							<p className='mr-[10px]'>数量</p>
							<input
								name='number'
								onChange={handleChange}
								type='number'
								min='1'
								max={product.stock}
								value={item.number}
								className='w-[60px] h-[40px] p-[10px] border text-[15px]' />
						</div>
						:
						<p className='mt-[10px] font-mono text-amber-600'>在庫なし</p>
						}
						{ (product.category !== '体験イベント' && product.sub_category !== '体験イベント') ? 
						<button
							onClick={handleSubmit}
							id='button'
							className='w-full p-[10px] mx-auto mt-[20px] block text-gold-light font-bold rounded bg-stone-400'>
							買い物かごに追加
						</button>
						:
						<button
							onClick={handleActivitySubmit}
							id='button'
							className='w-full p-[10px] mx-auto mt-[20px] block text-gold-light font-bold rounded bg-stone-400'>
							お申し込みフォームへ
						</button>
						}
					</div>
				</div>
				<div className='h-fit sm:w-0 sm:hidden'>
					<p className='mt-[40px] font-allura text-gold-dark'>Details</p>
					<p className='font-shippori text-[20px]'>詳細</p>
					<p className='mt-[20px] whitespace-pre-line leading-loose'>{product.description}</p>
				</div>
			</div>
			<ProductReview productId={productId} />
			<PopularItems />
		</div>
	);
};

export default Product;
