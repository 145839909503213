import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { ArticleBanners, Articles, PopularItems } from '../components';
import { pagesList } from '../data/main';
import { aboutText } from '../data/top';
import { getNews } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const Top = () => {
	const [news, setNews] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			context.setLoading(true);
			var newsData;
			try {
				newsData = await getNews();
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setNews(newsData);
			context.setLoading(false);
		}
		getData();
	}, []);

	const Menu = () => {
		return (
			<div className='flex-none w-0 sm:w-[320px] items-center hidden sm:block'>
				<a href='/'>
					<img src="/logo.jpg" alt="ロゴ" className='my-10 mx-auto w-[150px]'/>
				</a>
				<ul className="mx-auto w-[170px]">
					{ pagesList.map((p, i) => {
						return (
							<li key={i}>
								<a href={p.pathname} className='p-2 block hover:text-gold-light hover:bg-gold-dark'>
									{p.title}
								</a>
							</li>
						);
					})}
					<li>
						<a href='achievements' className='p-2 block hover:text-gold-light hover:bg-gold-dark'>
							メディア・講演
						</a>
					</li>
					<li>
						<a href='mailto:baikoan@outlook.jp' className='p-2 block hover:text-gold-light hover:bg-gold-dark'>
							お問い合わせ
						</a>
					</li>
				</ul>
			</div>
		);
	};

	const About = () => {
		return (
			<div>
				<div>
					<p className='font-allura text-gold-dark sm:text-[32px]'>About BAIKOAN</p>
					<p className='font-shippori text-[24px] sm:text-[32px]'>梅古庵について</p>
					<div className='mt-[20px] mb-[40px] sm:mb-[80px]'>
						<img alt='梅古庵についての画像' className='sm:w-1/2 mb-[10px] sm:m-[10px] rounded float-right' src='images/top/ubai_123.jpg' />
						<p className='whitespace-pre-line leading-loose'>
							{aboutText}
						</p>
					</div>
				</div>
				<div className='w-fit mx-auto sm:flex gap-[80px]'>
					<a href='/achievements' className='mb-[20px] sm:mb-0 btn-gold'>メディア・講演 &gt;</a>
					<a href='/about' className='btn-gold'>代表メッセージ &gt;</a>
				</div>
				<iframe
					src="https://www.youtube.com/embed/"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowfullscreen
					className='mx-auto mt-[80px] w-[800px] max-w-full aspect-video bg-stone-400'/>
				<a href='/ubai' className='btn-gold mx-auto mt-[20px] sm:mt-[80px]'>烏梅について &gt;</a>
			</div>
		)
	};

	const News = () => {
		return (
			<div>
				<p className='text-center font-allura text-gold-dark sm:text-[20px]'>News</p>
				<p className='mb-[40px] sm:mb-[80px] text-center font-shippori text-[24px] sm:text-[40px]'>お知らせ</p>
				<div className='py-[10px] w-full h-[400px] bg-gold rounded-[24px]'>
					<ul className='w-full h-full overflow-auto'>
						{ news.length ? news.map((n, i) => {
							return (
							<li key={i} className='mx-auto py-[10px] sm:py-0 sm:pl-[40px] w-4/5 min-h-[60px] sm:flex gap-[20px] border-b border-gold-light items-center' >
								<p className='w-[130px]'>{n.date}</p>
								<p>{n.content}</p>
							</li>);
						}):''}
					</ul>
				</div>
			</div>
		);
	};

	const Access = () => {
		return (
			<div>
				<div>
					<p className='text-center font-allura text-gold-dark sm:text-[20px]'>Access</p>
					<p className='mb-[40px] sm:mb-[80px] text-center font-shippori text-[24px] sm:text-[40px]'>アクセス</p>
				</div>
				<iframe
					title='access'
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.886245678384!2d136.0402645!3d34.708049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60014e37128013d9%3A0x6a1893b48333f383!2z44CSNjMwLTIzMDIg5aWI6Imv55yM5aWI6Imv5biC5pyI44O254Cs5bC-5bGx77yS77yS77yW77yT!5e0!3m2!1sja!2sjp!4v1722202458979!5m2!1sja!2sjp"
					className='mx-auto w-[800px] max-w-full aspect-video bg-stone-400 rounded-[10px]'
					allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
			</div>
		)
	};

	return (
		<div>
			<div className='flex h-[80vh] sm:h-screen sm:min-h-[600px]'>
				<Menu />
				<div className='relative fade-images w-full sm:min-w-0'>
					{ [...Array(11)].map((_, i) => { return (
						<div
							key={i}
							className='w-full h-full rounded-bl-[100px] object-cover'
							style={{
								'background-image': `url('images/top/${i + 1}.jpg')`,
								'animation-delay': `${i * 5}s`,
								'animation-duration': `${11 * 5}s`,
							}}/>
					)})}
					<img src="/logo.jpg" alt="ロゴ" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[80%] my-10 mx-auto w-[150px] h-[150px] p-2 object-cover bg-gold-light bg-opacity-80 rounded-full sm:hidden'/>
				</div>
			</div>
			<div className='w-[90%] sm:w-4/5 mx-auto pt-[80px] sm:pb-[120px]'>
				<About />
			</div>
			<div className='w-[90%] sm:w-4/5 mx-auto py-[80px] sm:py-[80px]'>
				<News />
			</div>
			<div className='w-[90%] sm:w-4/5 mx-auto'>
				<ArticleBanners articleList={['ubai', 'narabeni', 'agriculture', 'temple']} />
			</div>
			<div className='w-[90%] sm:w-4/5 mx-auto py-[80px]'>
				<Articles />
			</div>
			<img src='images/top/products.jpg' alt='' className='mt-[40px] sm:my-[80px] w-full h-[150px] sm:h-[300px] object-cover'/>
			<div className='w-[90%] sm:w-4/5 mx-auto'>
				<PopularItems />
			</div>
			<div id='access' className='w-[90%] sm:w-4/5 mx-auto pb-[80px]'>
				<Access />
			</div>
		</div>
	);
};

export default Top;
