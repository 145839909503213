import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { areaList, cashCommission, paymentList, prefectureList } from '../data/main';
import { createCart, createCheckoutSession, createOrders, getIndexBase64Images, getShippingFees, imageSrc } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const CartComponent = ({cart, shippingFee, setShippingMethods, payment}) => {
	const [sum, setSum] = useState(0);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const navigate = useNavigate();
	const context = useContext(LoadingContext);

	useEffect(() => {
		if (cart.length && Object.keys(cart[0]).length) {
			for (var i = 0; i < cart.length; i ++) {
				if (cart[i].subscription && (cart[i].number !== 1 || i !== 0)) {
					window.alert('定期便の購入は1度につき1つまでです。\nまた、他の商品との同時購入はできません。');
					navigate('/cart');
					return ;
				}
			}

			var sum_calc = 0;
			const shippingMethodList = [];

			cart.map((item) => {
				const i = shippingMethodList.findIndex(
					({method_id}) => method_id === item.shipping_method);
				if (i < 0)
					shippingMethodList.push({method_id: item.shipping_method, number: item.number});
				else
					shippingMethodList[i].number += item.number;
				sum_calc += item.price * item.number;
				return item;
			});
			if (payment === 'cash')
				sum_calc += cashCommission;
			setSum(sum_calc);
			setShippingMethods(shippingMethodList);

			const getData = async () => {
				context.setLoading(true);
				var base64ImagesData;
				try {
					base64ImagesData = await getIndexBase64Images({ table: 'products', objects: cart });
				} catch (err) {
					showBoundary(err);
					return ;
				}
				setBase64Images(base64ImagesData);
				context.setLoading(false);
			};
			getData();
		} else if (!cart.length) {
			navigate('/cart');
		}
	}, [cart]);

	return (
		<div className='w-full sm:w-2/5'>
			<div className='px-[10px] pt-[10px] pb-[30px] border border-stone-400 rounded'>
				<a href='/cart' className='text-sm'>&lt; <span className='hover:underline'>買い物かごに戻る</span></a>
				<ul className='my-[40px] mx-auto w-[90%] flex flex-col gap-[20px]'>
					{(cart.length && Object.keys(cart[0]).length) ? cart.map((item, i) => { return (
						<li key={i} className='flex gap-[10px]'>
							<img
								src={imageSrc(base64Images[item.base64Images_idx])}
								alt='商品画像'
								className='w-[70px] h-[70px] aspect-square object-cover rounded' />
							<div className='flex-1'>
								<a
									href={'/products/' + item.product_id}
									className='line-clamp-2 hover:underline'>
									{item.name}
								</a>
								<p className='text-end font-mono font-bold'>
									¥{new Intl.NumberFormat().format(item.price)} × {item.number}
								</p>
							</div>
						</li>
					)}):''}
				</ul>
				<div className='w-[90%] mx-auto flex font-mono'>
					<p className='w-full'>小計</p>
					<p className='w-full text-right'>¥{new Intl.NumberFormat().format(sum)}</p>
				</div>
				{ payment === 'cash' ? 
					<div className='w-[90%] mx-auto flex font-mono'>
						<p className='w-full'>代引き手数料</p>
						<p className='w-full text-right'>¥{cashCommission}</p>
					</div>
					:''
				}
				<div className='w-[90%] mx-auto pb-[10px] border-b border-stone-400 flex font-mono'>
					<p className='w-full'>送料</p>
					<p className='w-full text-right'>¥{new Intl.NumberFormat().format(shippingFee)}</p>
				</div>
				<div className='w-[90%] mt-[10px] mx-auto flex font-mono text-[24px] font-bold'>
					<p className='w-full'>合計</p>
					<p className='w-full text-right'>¥{new Intl.NumberFormat().format(sum + shippingFee)}</p>
				</div>
			</div>
		</div>
	);
};

const FormComponent = ({setInputMode, cart, customerData, setCustomerData, shippingMethods, setShippingFee}) => {
	const [agreeStatus, setAgreeStatus] = useState(false);
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);

	const handleChange = (e) => {
		e.preventDefault();
		setCustomerData({...customerData, [e.target.name]: e.target.value});
	};

	const changeShippingFee = async (prefecture) => {
		const area = areaList.find(
			(area) => area.prefectures.find((p) => p === prefecture));
		var shippingFeeCalc = 0;

		await Promise.all(shippingMethods.map(async (method) => {
			if (!method.method_id)
				return ;
			const shippingFeesData = await getShippingFees(method.method_id);
			var n = method.number;
			var shippingFeeData = shippingFeesData.find(
				(data) => data.min_n <= n && n <= data.max_n);
			if (!shippingFeeData) {
				shippingFeeData = shippingFeesData[shippingFeesData.length - 1];
			}
			if (shippingFeeCalc < shippingFeeData[area.method_name])
				shippingFeeCalc = shippingFeeData[area.method_name];
		})).catch((err) => showBoundary(err));
		setShippingFee(shippingFeeCalc);
	};

	const handleAreaChange = async (e) => {
		e.preventDefault();
		context.setLoading(true);

		setCustomerData({...customerData, [e.target.name]: e.target.value});
		await changeShippingFee(e.target.value);

		context.setLoading(false);
	};

	const handleSameAddressClick = async (e) => {
		e.preventDefault();
		context.setLoading(true);

		setCustomerData({
			...customerData,
			deliver_name: customerData.name,
			deliver_zipCode: customerData.zipCode,
			deliver_prefecture: customerData.prefecture,
			deliver_address: customerData.address,
		});
		if (customerData.prefecture) {
			await changeShippingFee(customerData.prefecture);
		}

		context.setLoading(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!agreeStatus) {
			window.alert('プライバシーポリシーに同意してください');
			return ;
		}
		setInputMode(false);
	};

	const TransactionsModal = () => {
			return (
				<dialog id="transactions_modal" className="w-[640px] h-[480px] modal modal-bottom sm:modal-middle p-4 rounded-md">
					<div className="modal-box">
						<form method="dialog">
							<button className="btn btn-sm btn-ghost absolute right-4 top-4">✕</button>
						</form>
						<h3 className="font-bold text-lg">特定商法取引法に基づく表記</h3>
						<p className="py-4 whitespace-pre-line leading-loose">{`\
							事業者の名称： 梅古庵　中西謙介

							事業者の所在地： 〒630-2302 奈良県奈良市月ケ瀬尾山2263

							事業者の連絡先： 0743-92-0560

							販売価格： 販売価格は、表示された金額（表示価格/消費税込）と致します。
							★送料・お振込手数料は別途お客様負担となります。

							代金の支払方法・時期： クレジットカード決済、銀行振込（先払い）、商品代引がご利用いただけます。
							商品注文確定時でお支払いが確定致します。
							★「銀行振込決済」を選択された場合、土日祝日の入金は翌営業日まで確認が出来ませんので、特に＜連休前＞などは時間に余裕をもったご注文をお願いいたします。
							★代金決済前後に関わらずご注文確定後のキャンセルはお断りします。

							銀行振込決済（ご請求後5営業日以内のお支払い。お振込み確認出来ない場合はキャンセルさせていただきます。）

							商品のお届け時期： ご入金確認後、4日以内に発送いたします。

							返品について： 商品に欠陥がある場合を除き、基本的には返品には応じません。
						`}</p>
					</div>
				</dialog>
			);
	};

	const PolicyModal = () => {
			return (
				<dialog id="policy_modal" className="w-[640px] h-[480px] modal modal-bottom sm:modal-middle p-4 rounded-md">
					<div className="modal-box">
						<form method="dialog">
							<button className="btn btn-sm btn-ghost absolute right-4 top-4">✕</button>
						</form>
						<h3 className="font-bold text-lg">プライバシーポリシー</h3>
						<p className="py-4 whitespace-pre-line leading-loose">{`\
							当ショップは、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。
							1. 個人情報の定義本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）、もしくは個人識別符号が含まれる情報を意味するものとします。

							2. 個人情報の利用目的当ショップは、お客様の個人情報を、以下の目的で利用致します。
							（１） 当ショップサービスの提供のため
							（２） 当ショップサービスに関するご案内、お問い合わせ等への対応のため
							（３） 当ショップの商品、サービス等のご案内のため
							（４） 当ショップサービスに関する当ショップの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
							（５） 当ショップサービスに関する規約等の変更などを通知するため
							（６） 当ショップサービスの改善、新サービスの開発等に役立てるため
							（７） 当ショップサービスに関連して、個別を識別できない形式に加工した統計データを作成するため
							（８） その他、上記利用目的に付随する目的のため

							3. 個人情報利用目的の変更当ショップは、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。

							4. 個人情報利用の制限当ショップは、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
							（１） 法令に基づく場合
							（２） 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき
							（３） 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき
							（４） 国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき

							5. 個人情報の適正な取得当ショップは、適正に個人情報を取得し、偽りその他不正の手段により取得しません。

							6. 個人情報の安全管理当ショップは、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当ショップの従業員に対し、必要かつ適切な監督を行います。また、当ショップは、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。

							7. 第三者提供当ショップは、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめお客様の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
							（１） 当ショップが利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合
							（２） 合併その他の事由による事業の承継に伴って個人情報が提供される場合
							（３） 個人情報保護法の定めに基づき共同利用する場合

							8. 個人情報の開示当ショップは、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当ショップが開示の義務を負わない場合は、この限りではありません。

							9. 個人情報の訂正等当ショップは、お客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、お客様ご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当ショップが訂正等の義務を負わない場合は、この限りではありません。

							10. 個人情報の利用停止等当ショップは、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨をお客様に通知します。但し、個人情報保護法その他の法令により、当ショップが利用停止等の義務を負わない場合は、この限りではありません。

							11. お問い合わせ開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、当ショップの「特定商取引法に基づく表記」内にある連絡先へご連絡いただくか、ショップページ内のお問い合わせフォームよりお問い合わせください。
							12. 継続的改善当ショップは、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。
						`}</p>
					</div>
				</dialog>
			);
	};

	return (
		<form onSubmit={handleSubmit} className='w-full sm:order-first flex-1 sm:pr-[10px]'>
			<TransactionsModal />
			<PolicyModal />
			<p className='font-allura text-gold-dark sm:text-[20px]'>Cart</p>
			<p className='mb-[40px] font-shippori text-[20px] sm:text-[32px]'>お支払い</p>
			<div>
				<label>メールアドレス<span className='ml-[5px] text-amber-600'>*</span></label>
				<input
					onChange={handleChange} name='email' type='email'
					className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
					value={customerData.email} required />
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>購入者情報</p>
				<div className='mb-[20px]'>
					<label>氏名<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='name' type='text'
						className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
						value={customerData.name} required />
				</div>
				<div className='mb-[20px] flex flex-col sm:flex-row gap-[20px] sm:gap-2'>
					<div className='flex-1'>
						<label>郵便番号（半角数字7桁）<span className='ml-[5px] text-amber-600'>*</span></label>
						<input
							onChange={handleChange} name='zipCode' type='text' pattern="\d{7}"
							className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
							value={customerData.zipCode} required />
					</div>
					<div className='flex-1'>
						<label>都道府県<span className='ml-[5px] text-amber-600'>*</span></label>
						<select
							onChange={handleChange} name='prefecture'
							className='w-full mt-[5px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
							value={customerData.prefecture} required>
							<option value="">選択してください</option>
							{prefectureList.map((p, i) => {
								return <option key={i} value={p}>{p}</option>
							})}
						</select>
					</div>
				</div>
				<div className='mb-[20px]'>
					<label>ご住所<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='address' type='text'
						className='w-full mt-[5px] h-[50px] py-[10px] px-[16px] border border-stone-200 rounded invalid:border-amber-600'
						value={customerData.address} required />
				</div>
				<div>
					<label>電話番号（半角数字11桁）<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='phone' type='tel' pattern="\d{11}"
						className='w-full mt-[5px] h-[50px] py-[10px] px-[16px] border border-stone-200 rounded invalid:border-amber-600'
						value={customerData.phone} required />
				</div>
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>お届け先情報</p>
				<button onClick={handleSameAddressClick} className='mb-[10px] rounded-[8px] p-[10px] bg-gold'>購入者情報と同じ住所を入力する</button>
				<div className='mb-[20px]'>
					<label>氏名<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='deliver_name' type='text'
						className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
						value={customerData.deliver_name} required />
				</div>
				<div className='mb-[20px] flex flex-col sm:flex-row gap-[20px] sm:gap-2'>
					<div className='flex-1'>
						<label>郵便番号（半角数字7桁）<span className='ml-[5px] text-amber-600'>*</span></label>
						<input
							onChange={handleChange} name='deliver_zipCode' type='text' pattern="\d{7}"
							className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
							value={customerData.deliver_zipCode} required />
					</div>
					<div className='flex-1'>
						<label>都道府県<span className='ml-[5px] text-amber-600'>*</span></label>
						<select
							onChange={handleAreaChange} name='deliver_prefecture'
							className='w-full mt-[5px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
							value={customerData.deliver_prefecture} required>
							<option value="">選択してください</option>
							{prefectureList.map((p, i) => {
								return <option key={i} value={p}>{p}</option>
							})}
						</select>
					</div>
				</div>
				<div>
					<label>ご住所<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='deliver_address' type='text'
						className='w-full mt-[5px] h-[50px] py-[10px] px-[16px] border border-stone-200 rounded invalid:border-amber-600'
						value={customerData.deliver_address} required />
				</div>
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>お支払い方法</p>
				<p className='flex'>
					{paymentList.map((p, i) => {return (
						<label key={i} className='flex-1'>
							<input
								onChange={(e) => {
									setCustomerData({...customerData, [e.target.name]: e.target.value});
								}}
								type='radio'
								name='payment'
								value={p.value}
								required
							/>
							{p.label}
						</label>
					)})}
				</p>
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>備考欄</p>
				<textarea
					onChange={handleChange} name='memo'
					className='p-[10px] w-full h-[160px] border border-stone-200 rounded' />
			</div>
			<div className='flex flex-col items-center'>
				<button
					onClick={(e) => {
						e.preventDefault();
						document.getElementById('policy_modal').showModal()
					}}
					className="font-mono text-stone-400 flex items-center">
					<span className='mb-[5px] text-gold-dark underline'>プライバシーポリシーを確認する</span>
				</button>
				<div className='flex items-center gap-[5px]'>
					<input
						onChange={() => setAgreeStatus(!agreeStatus)}
						name='agree_status'
						type='checkbox'
						value={agreeStatus}
						checked={agreeStatus}
						className='w-4 h-4 checked:bg-gold' />
					<label for='agree_status'>プライバシーポリシーに同意する</label>
				</div>
				<button
					onClick={(e) => {
						e.preventDefault();
						document.getElementById('transactions_modal').showModal()
					}}
					className="font-mono text-stone-400 flex items-center">
					<span className='mt-[30px] mb-[5px] text-gold-dark underline'>特定商法取引法に基づく表記</span>
				</button>
				<button
					type='submit'
					className='w-full sm:w-[400px] mx-auto my-[40px] sm:mt-[20px] sm:mb-[80px] p-[10px] block text-center text-gold-light rounded-[8px] bg-stone-800 hover:bg-gold'>
					次へすすむ
				</button>
			</div>
		</form>
	);
};

const ConfirminationComponent = ({setInputMode, cart, customerData}) => {
	const context = useContext(LoadingContext);
	const { showBoundary } = useErrorBoundary();
	const navigate = useNavigate();

	const handleClick = async (e) => {
		e.preventDefault();
		context.setLoading(true);

		const cartStorage = JSON.parse(localStorage.getItem('cart'));
		const result = cartStorage.find(
			(item, i) => (item.product_id !== cart[i].product_id || item.number !== cart[i].number));
		if (result || cart.length !== cartStorage.length) {
			window.alert("カートの内容が変更された可能性があります。");
			context.setLoading(false);
			navigate('/cart');
			return ;
		}

		if (customerData.payment === 'card') {
			try {
				await createCheckoutSession({cart, customer: customerData});
			} catch (err) {
				showBoundary(err);
			}
		} else {
			try {
				await createOrders({cart, customer: customerData});
			} catch (err) {
				showBoundary(err);
			}
		}
		context.setLoading(false);
	};

	const handleBackToForm = () => {
		setInputMode(true);
	};

	return (
		<div className='w-full sm:order-first flex-1 sm:pr-[10px]'>
			<p className='font-allura text-gold-dark sm:text-[20px]'>Cart</p>
			<p className='mb-[40px] font-shippori text-[20px] sm:text-[32px]'>お支払い</p>
			<div>
				<label>メールアドレス<span className='ml-[5px] text-amber-600'>*</span></label>
				<p>{customerData.email}</p>
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>購入者情報</p>
				<div className='mb-[20px]'>
					<label>氏名<span className='ml-[5px] text-amber-600'>*</span></label>
					<p>{customerData.name}</p>
				</div>
				<div className='mb-[20px]'>
					<label>ご住所<span className='ml-[5px] text-amber-600'>*</span></label>
					<p>〒{customerData.zipCode}</p>
					<p>{customerData.prefecture}</p>
					<p>{customerData.address}</p>
				</div>
				<div>
					<label>電話番号（半角数字11桁）<span className='ml-[5px] text-amber-600'>*</span></label>
					<p>{customerData.phone}</p>
				</div>
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>お届け先情報</p>
				<div className='mb-[20px]'>
					<label>氏名<span className='ml-[5px] text-amber-600'>*</span></label>
					<p>{customerData.deliver_name}</p>
				</div>
				<div>
					<label>ご住所<span className='ml-[5px] text-amber-600'>*</span></label>
					<p>{customerData.deliver_address}</p>
				</div>
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>お支払い方法</p>
				<p>
				{ paymentList.map((p) => {
					if (p.value === customerData.payment)
						return p.label;
					return '';
				})}
			</p>
			</div>
			<div className='my-[40px]'>
				<p className='mb-[20px] text-[20px] sm:text-[24px]'>備考欄</p>
				<p>{customerData.memo}</p>
			</div>
			<div className='flex flex-col items-center'>
				<button
					onClick={handleClick}
					type='submit'
					className='w-full sm:w-[400px] mx-auto mt-[40px] sm:mt-[20px] p-[10px] block text-center text-gold-light rounded-[8px] bg-stone-800 hover:bg-gold transition'>
					お支払いへ
				</button>
				<button
					onClick={handleBackToForm}
					className='w-full sm:w-[400px] mx-auto mt-[10px] mb-[40px] sm:mb-[80px] p-[10px] block text-center text-gold-light rounded-[8px] bg-stone-400 hover:bg-stone-600 transition'>
					入力画面に戻る
				</button>
			</div>
		</div>
	);
}

const OrderForm = () => {
	const [cart, setCart] = useState([{}]);
	const [customerData, setCustomerData] = useState({
		email: '',
		phone: '',
		name: '',
		zipCode: '',
		prefecture: '',
		address: '',
		deliver_name: '',
		deliver_zipCode: '',
		deliver_prefecture: '',
		deliver_address: '',
		payment: '',
		memo: ''
	});
	const [inputMode, setInputMode] = useState(true);
	const [shippingMethods, setShippingMethods] = useState([]);
	const [shippingFee, setShippingFee] = useState(0);
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);

	useEffect(() => {
		const createCartFunc = async () => {
			context.setLoading(true);
			try {
				await createCart(setCart);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			context.setLoading(false);
		}
		createCartFunc();
	}, []);

	return (
		<div className='mt-[60px] sm:mt-[80px] mx-auto py-[40px] sm:py-[80px] w-[90%] sm:w-4/5 flex flex-wrap gap-[40px] sm:gap-[10px]'>
			<CartComponent cart={cart} shippingFee={shippingFee} setShippingMethods={setShippingMethods} payment={customerData.payment} />
			{ inputMode ? 
				<FormComponent setInputMode={setInputMode} cart={cart} customerData={customerData} setCustomerData={setCustomerData} shippingMethods={shippingMethods} setShippingFee={setShippingFee} />
				:
				<ConfirminationComponent setInputMode={setInputMode} cart={cart} customerData={customerData} />
			}
		</div>
	);
};

export default OrderForm;
