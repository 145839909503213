import { React, useEffect, useState } from 'react';
import { imageSrc } from '../functions';

const ProductsIndex = ({products, category, base64Images}) => {
	const [categorizedProducts, setCategorizedProducts] = useState(products);

	useEffect(() => {
		if (products.length) {
			const categorizedProductsData = products.filter((p) => {
				return (!category || p.category === category || p.sub_category === category)
			});
			setCategorizedProducts(categorizedProductsData);
		}
	}, [products, category]);

	return (
		categorizedProducts.length ? 
		<ul className='grid grid-cols-2 xl:grid-cols-3 gap-x-[20px] gap-y-[40px]' >
		{ categorizedProducts.length ? categorizedProducts.map((p, i) => {
			return (
				<li key={i}>
					<div className='hover:opacity-60 transition'>
						<a href={'/products/' + p.id}>
							<img
								src={imageSrc(base64Images[p.base64Images_idx])}
								alt='goods'
								className='mb-[10px] mx-auto w-full sm:w-[325px] aspect-square sm:aspect-video object-cover rounded-[12px]' />
							<div className='sm:w-[325px] mx-auto'>
								<p className='sm:mb-[10px] sm:py-2 line-clamp-2'>
									{p.name}
								</p>
								<p className='text-[20px] font-mono font-bold text-center'>
									¥{new Intl.NumberFormat().format(p.price)}
								</p>
								{p.stock ? '' : 
									<p className='text-sm text-center text-amber-600 font-mono'>在庫なし</p>
								}
							</div>
						</a>
					</div>
				</li>
			);
		}):''}
		</ul>
	:
		<p className='mt-20 mb-80 text-center'>
			{ category ? `「${category}」に` : ''}該当する商品はありません
		</p>
	);
};

export default ProductsIndex;